.button {
  background-color: #f7801a !important;
  width: 100% !important;
  height: 56px !important;
  border-radius: 10px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  margin-top: 25px !important;
  color: #ffff !important;
}
.button > p {
  color: #ffffff !important;
  /* font-family: "Switzer-Regular" !important; */
  font-size: 16px !important;
  font-weight: 400 !important;
  text-transform: capitalize !important;
}
