.react-pdf__Page__canvas{
    height: 100% !important;
    max-width: 98% !important;
    margin: 0 auto;
    width: 100% !important;
}
.react-pdf__Page{
    height: 100% !important;
    max-width: 98% !important;
    margin: 0 auto;
    width: 100% !important;


}
.react-pdf__Page__textContent {
    height: 100% !important;
    max-width: 98% !important;
    margin: 0 auto;
    width: 100% !important;

}
@media print {
    .pdf-page {
      page-break-after: always;
      page-break-inside: avoid;
      margin: 0 auto;
      padding: 0;
      max-width: 98% !important;
      width: 100% !important;


    }
  
    .pdf-page canvas {
      margin: 0 auto;
      padding: 0;
      max-width: 98% !important;
      height: auto !important;
      width: 100% !important;

    }
  }
  