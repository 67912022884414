/* Loader styling */
.lds-ring,
.lds-ring div {
  box-sizing: border-box;
}
.loader-loading-margin{
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 20vw;  
  height: 20vw; 
  max-width: 80px; 
  max-height: 80px; 
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 80%;  
  height: 80%; 
  margin: 10%; 
  border: 0.8vw solid #f7801a; 
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #f7801a transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


@media (max-width: 768px) {
  .lds-ring {
    width: 40vw;  
    height: 40vw; 
  }
}

@media (max-width: 480px) {
  .lds-ring {
    width: 50vw;  
    height: 50vw;
  }
}

/* Center the loader in the container */
.loader-container {
  display: flex;
  justify-content: center;  
  align-items: center;               
  width: 100%;             
}



.css-18lrjg1-MuiCircularProgress-root{
  color: #fff !important;
}