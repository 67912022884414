.input-title {
  color: #f7801a;
  /* font-family: "Lexend"; */
  font-size: 14px;
  font-weight: 300;
  margin-top: 15px;
}
.input-main {
  border: 1px solid #a2a1a833;
  height: 56px;
  border-radius: 10px;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0px 15px;
}
.input-main > input {
  width: 100%;
  height: 100%;
  color: #16151c;
  /* font-family: "Switzer-Light"; */
  font-size: 16px;
  font-weight: 300;
}
.input-main > button {
  height: 100%;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.input-password-icon {
  width: 24px;
  height: 24px;
}
