.order-table-checkbox {
  border: 1px solid #f7801a !important;
  height: 20px !important;
  width: 20px !important;
  min-width: 20px !important;
  min-height: 20px !important;
  max-width: 20px !important;
  max-height: 20px !important;
  border-radius: 5px !important;
  padding: 0px !important;
}
.order-table-text {
  color: #1d1d1d;
  /* font-family: "Poppins"; */
  font-size: 12px;
  font-weight: 400;
}
.order-table-header-title {
  color: #333333;
  /* font-family: "Poppins"; */
  font-size: 12px;
  font-weight: 600;
}
.order-table-status-delivered {
  color: #089e2e;
  /* font-family: "Poppins"; */
  font-size: 12px;
  font-weight: 400;
}
.order-table-dropdown {
  background-color: #f5f5f5;
  border: 1px solid #0000001a;
  height: 27px;
  border-radius: 8px;
  color: #1d1d1d;
  /* font-family: "Poppins"; */
  font-size: 12px;
  font-weight: 400;
  width: 100%;
}
.order-table-action-btn{
    height: 35px !important;
    width: 35px !important;
    min-width: 35px !important;
    min-height: 35px !important;
    max-width: 35px !important;
    max-height: 35px !important;
    border-radius: 100px !important;
    padding: 0px !important;
}

.not-job{
margin: 20px ;
}