/* Stack layout for buttons */
.stack {
    display: flex;
    gap: 10px;
  }
  
  /* Notification button styles */
  .notification-button {
    position: relative;
    border: none;
    background: none;
    cursor: pointer;
  }
  
  .notification-icon {
    font-size: 24px;
  }
  
  .notification-badge {
    position: absolute;
    top: 0;
    right: 0;
    background-color: #F7801A;
    color: white;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
  }
  
  /* Avatar button styles */
  .avatar-button {
    display: flex;
    align-items: center;
    border: none;
    background: none;
    cursor: pointer;
  }
  
  .avatar {
    width: 40px;
    height: 40px;
    background-color: #F7801A;
    color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
  }
  
  .avatar-arrow {
    margin-left: 8px;
    font-size: 24px;
  }
  
  /* Popover styles */
  .popover {
    position: absolute;
    background-color: white;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    z-index: 1;
    padding: 10px;
  }
  
  .popover-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .popover-item {
    padding: 8px 0;
    cursor: pointer;
  }
  