@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap');

body {
  margin: 0;
  font-family: "Plus Jakarta Sans", sans-serif !important;

}

code {
  font-family: "Plus Jakarta Sans", sans-serif;

}
